import { ArchiveIcon } from '../icons/ArchiveIcon';
import { MinusCircleIcon } from '../icons/MinusCircleIcon';
import { RightArrowIcon } from '../icons/RightArrowIcon';

import { ExclamationTriangleIcon } from '@/app/components/icons/ExclamationTriangleIcon';
import { HalfCircleCheckMark } from '@/app/components/icons/HalfCircleCheckMark';
import { HourglassIcon } from '@/app/components/icons/HourglassIcon';
import { InfoIcon } from '@/app/components/icons/InfoIcon';
import { CaseStatus } from '@/consts/sheerCaseConsts';

interface SheerCasePreviewProps {
  title: string;
  status?: string;
  onClick: () => void;
}

const { COMPLETE, DISMISSED, IN_PROGRESS, MAY_NEED_ACTION, NEEDS_ACTION } =
  CaseStatus;

const defaultStyle =
  'cursor-pointer flex justify-between gap-4 items-center w-full rounded-[8px] p-6 ';
const className = {
  [IN_PROGRESS]: defaultStyle + 'bg-[#F5EFFF] text-[#211F26]',
  [COMPLETE]: defaultStyle + 'bg-[#E7F9F5] text-[#0D3D38]',
  [MAY_NEED_ACTION]: defaultStyle + 'bg-[#FFF3D0] text-[#211F26]',
  [NEEDS_ACTION]: defaultStyle + 'bg-[#FEEFF3] text-[#64172B]',
  [DISMISSED]: defaultStyle + 'bg-[#F3F1F5] text-[#211F26]',
  default: defaultStyle + 'bg-[#F3F1F5] text-[#211F26]',
};

const typeIcon = {
  [IN_PROGRESS]: <HourglassIcon />,
  [MAY_NEED_ACTION]: <InfoIcon />,
  [NEEDS_ACTION]: <ExclamationTriangleIcon />,
  [COMPLETE]: <HalfCircleCheckMark />,
  [DISMISSED]: <MinusCircleIcon />,
  default: <ArchiveIcon />,
};

export const SheerCasePreview = ({
  onClick,
  status = 'default',
  title,
}: SheerCasePreviewProps) => {
  return (
    <button className={className[status]} onClick={onClick}>
      <div className='flex items-center w-full gap-4'>
        <div className='w-4'>{typeIcon[status]}</div>
        <p className='text-[16px] text-left text-[#211F26]'>{title}</p>
      </div>
      <RightArrowIcon />
    </button>
  );
};
