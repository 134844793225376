'use client';

import { Connection } from '@api/app/v1/app_pb';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { AmountSavedButton } from '@/app/components/AmountSavedButton';
import ConnectionCard from '@/app/components/ConnectionCard';
import { Loader } from '@/app/components/Loader';
import { SheerCasePreview } from '@/app/components/sheercase/SheerCasePreview';
import { SupportButtonSection } from '@/app/components/SupportButtonSection';
import useAuthRedirect from '@/hooks/useAuthRedirectPortal';
import useEventUpdates from '@/hooks/useEventUpdates';
import useMemberData from '@/hooks/useMemberData';
import PlanAnalysisGirl from '@/public/images/PlanAnalysisGirl.png';
import Image from 'next/image';
import { remoteConfig } from '@/firebase/remoteConfig';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { Button } from '@/app/components/Button';

const getGreeting = (memberName?: string) => {
  const hrs = new Date().getHours();
  const greeting =
    hrs < 12 ? 'Good morning' : hrs <= 17 ? 'Good afternoon' : 'Good evening';
  if (memberName) return `${greeting}, ${memberName}`;
  return `${greeting}.`;
};

const HomePage = () => {
  const router = useRouter();
  const { authLoading } = useAuthRedirect();
  const {
    amountSaved,
    isLoading: memberDataLoading,
    memberConnections,
    memberData,
    refreshMemberData,
  } = useMemberData();
  const { events: memberEvents, isLoading: eventsLoading } = useEventUpdates();
  const [showPlanAnalysisTile, setShowPlanAnalysisTile] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const getConfigValue = () => {
    const firebaseRemoteConfig = remoteConfig;

    if (firebaseRemoteConfig) {
      fetchAndActivate(firebaseRemoteConfig).then(() => {
        const showPlanAnalysisTile = getValue(
          firebaseRemoteConfig,
          'enable_plan_analysis',
        ).asBoolean();

        setShowPlanAnalysisTile(showPlanAnalysisTile);
        setShowLoader(false);
      });
    } else {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getConfigValue();
  }, []);

  const refreshData = async () => {
    setShowLoader(true);
    await refreshMemberData();
    setShowLoader(false);
  };

  const ConnectionSection = () => {
    const validConnections = memberConnections.filter(
      connection =>
        connection.payerName && connection.username && !connection.inactive,
    );
    const faultedConnections = validConnections.filter(
      connection => connection.faulted,
    );

    if (!validConnections.length) return null;

    if (faultedConnections.length)
      return memberConnections.map(
        (connection: Connection) =>
          connection.faulted && (
            <ConnectionCard connection={connection} refreshData={refreshData} />
          ),
      );

    return (
      <div className='flex flex-col w-full gap-4 pb-2 overflow-auto cursor-pointer lg:flex-row scrollbar-hide'>
        <Link
          className='bg-[#E7F9F5] text-[#067A6F] text-[16px] p-3 lg:px-5 rounded-[4px] text-center w-full lg:w-fit text-nowrap'
          href='/insurance'
        >
          ✓{' '}
          {validConnections.length > 1
            ? `${validConnections.length} connections`
            : `Connected to ${validConnections[0]?.payerName}`}
        </Link>
        <Link
          className='bg-[#F5EFFF] text-[#3D155D] text-[16px] p-3 lg:px-5 rounded-[4px] text-center w-full lg:w-fit text-nowrap'
          href='/connect'
        >
          {'Add connection ->'}
        </Link>
      </div>
    );
  };

  const EventPreviewList = () => {
    const maxDisplay = 3;
    const filteredEvents = memberEvents
      .filter(
        event =>
          event.messages.length > 0 && event.type !== 'Medical Plan Selection',
      )
      .slice(0, maxDisplay);

    if (!filteredEvents.length) {
      return (
        <div className='flex flex-col w-full gap-4 p-2 lg:w-full'>
          <p className='text-[18px] text-[#65636D]'>0 open messages</p>
          <SheerCasePreview
            title='View all messages'
            onClick={() => router.push('/messages')}
          />
        </div>
      );
    }

    return (
      <div className='flex flex-col w-full gap-4 p-2 lg:w-full'>
        <p className='text-[18px] text-[#65636D]'>Recent messages</p>
        {filteredEvents.map(event => (
          <SheerCasePreview
            key={event.id}
            title={event.title}
            status={event.status}
            onClick={() => router.push(`/messages/${event.id}`)}
          />
        ))}
        <SheerCasePreview
          title='View all messages'
          onClick={() => router.push('/messages')}
        />
      </div>
    );
  };

  const PlanAnalysisTile = () => {
    if (!showPlanAnalysisTile) return null;

    const planAnalysisEvent = memberEvents.find(
      event => event.type === 'Medical Plan Selection',
    );
    const eventStatus = planAnalysisEvent?.status;

    let outline = '';
    let buttonCopy = 'Get Started';
    let subtitle =
      'Choosing a new plan for 2025? Switching jobs? Preparing for life changes? Our new Plan Analysis tool is here to help.';

    switch (eventStatus) {
      case undefined:
        break;
      case 'Needs Action':
        outline = 'border-[1.5px] border-[#CA244D]';
        buttonCopy = 'Review & Complete';
        subtitle =
          "We're missing some details to complete your Plan Analysis. Please review and provide the information to proceed.";
        break;
      case 'Complete':
        buttonCopy = 'View Results';
        subtitle =
          'Your Plan Analysis is complete 🎉  Let us know if you have any questions. ';
        break;
      default:
        buttonCopy = 'View Status';
        subtitle =
          "Your Plan Analysis is in progress. We'll notify you once it's finished.";
    }

    const goToAnalysis = () => router.push('/plananalysis');

    return (
      <div className={'w-full card-background ' + outline}>
        <div className='flex flex-col items-start w-full gap-1'>
          <p className='w-full text-start text-xxl text-[#562F8C]'>
            Insurance Plan Analysis
          </p>
          <p className='mb-2 w-full text-start text-[#211F26]'>{subtitle}</p>
        </div>
        {!planAnalysisEvent && (
          <Image
            src={PlanAnalysisGirl}
            width={100}
            height={100}
            alt=''
            className='w-[100px] mb-3'
          />
        )}
        <Button
          onClick={goToAnalysis}
          variant={eventStatus === 'Needs Action' ? 'alert' : 'default'}
        >
          {buttonCopy}
        </Button>
      </div>
    );
  };

  if (
    !memberData.registered ||
    memberDataLoading ||
    eventsLoading ||
    authLoading ||
    showLoader
  ) {
    return <Loader />;
  }

  return (
    <div className='flex flex-col items-start justify-center w-full gap-6 px-4 py-6 bg-white lg:py-12 lg:flex-row lg:px-36'>
      <div className='flex flex-col w-full gap-4 px-4 lg:w-2/5 lg:max-w-[528px]'>
        <p className='text-[24px] lg:text-[32px] text-[#211F26] font-arizona-flare'>
          {getGreeting(memberData.firstName)}
        </p>
        <ConnectionSection />
        <hr className='max-w-[528px]' />

        <p className='text-[20px] text-[#65636D]'>How can we help you today?</p>
        <SupportButtonSection />
        {!!amountSaved && (
          <>
            <hr className='max-w-[528px] my-3' />
            <AmountSavedButton
              amountSaved={amountSaved}
              memberId={memberData.id}
            />
          </>
        )}
      </div>
      <div className='flex flex-col w-full gap-6 px-4 lg:w-5/12 2xl:w-1/3'>
        <PlanAnalysisTile />
        <EventPreviewList />
      </div>
    </div>
  );
};

export default HomePage;
