import { Connection, EditConnectionRequest } from '@api/app/v1/app_pb';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

import { Button } from '@/app/components/Button';
import { ExclamationTriangleIcon } from '@/app/components/icons/ExclamationTriangleIcon';
import { Portal, portalMap } from '@/consts/portalMap';
import { getFirebaseAuthToken } from '@/firebase/auth';
import useAppService from '@/hooks/useAppService';

const ConnectionCard = ({
  allowArchive,
  connection,
  refreshData,
}: {
  connection: Connection;
  refreshData: () => void;
  allowArchive?: boolean;
}) => {
  const router = useRouter();
  const getClient = useAppService();
  const [openMenu, setOpenMenu] = useState(false);

  const {
    faulted,
    id: connectionId,
    payerName,
    updatedAt,
    username,
  } = connection;

  const onMenuClick = () => setOpenMenu(!openMenu);

  const archiveConnection = async () => {
    const answer = window.confirm(
      'Are you sure you want to remove this connection? This action cannot be undone.',
    );

    if (!answer) return;

    const token = await getFirebaseAuthToken();
    const client = await getClient();

    if (!client || !token) return;

    const editConnectionRequest = {
      id: connectionId,
      inactive: true,
    } as EditConnectionRequest;

    return await client
      .editConnection(editConnectionRequest, {
        headers: { Authorization: token },
      })
      .then(refreshData)
      .then(() => setOpenMenu(false));
  };

  const pushToReconnect = () => {
    const payerPath = Object.keys(portalMap).find(
      key => portalMap[key as Portal].serverName === payerName,
    );

    router.push(`/connect/${payerPath}?reconnect=true`);
  };

  return (
    <div
      className={`relative p-6 rounded-[8px] gap-1 flex flex-col w-full bg-white [box-shadow:0px_16px_36px_-20px_var(--Neutral-color-Neutral-Alpha-7,_rgba(12,_1,_47,_0.17)),_0px_16px_64px_0px_var(--Neutral-color-Neutral-Alpha-2,_rgba(47,_5,_88,_0.02)),_0px_12px_60px_0px_var(--Overlays-Black-Alpha-3,_rgba(0,_0,_0,_0.05))] ${faulted && ' border-[2px] border-red-300'}`}
    >
      {openMenu && (
        <div className='absolute flex flex-col gap-1 p-1 right-2 top-12 bg-white rounded-[8px] shadow-md'>
          <button
            onClick={archiveConnection}
            className='px-4 py-2 text-[16px] text-slate-800 bg-gray-50 rounded-[4px]'
          >
            Archive connection
          </button>
        </div>
      )}

      <div className='flex items-start justify-between w-full'>
        <p className='text-[24px] text-[#562F8C]'>{payerName}</p>
        {allowArchive && (
          <button onClick={onMenuClick} className='text-[10px] text-slate-500'>
            •••
          </button>
        )}
      </div>

      <p className='text-[16px]'>{username}</p>
      <div className='text-[14px] flex items-center justify-start w-full gap-2 mt-4'>
        <ConnectionStatus status={faulted ? 'Disconnected' : 'Connected'} />
        <p>As of {updatedAt?.toDate().toLocaleDateString()}</p>
      </div>
      {faulted && (
        <Button onClick={pushToReconnect} variant='alert' className='mt-6'>
          Reconnect
        </Button>
      )}
    </div>
  );
};

const ConnectionStatus = ({ status }: { status: string }) => {
  if (status === 'Connected')
    return (
      <div className='bg-[#E7F9F5] text-[#067A6F] py-1 px-5 rounded-[4px]'>
        ✓ Connected
      </div>
    );

  return (
    <div className='flex items-center gap-2 bg-[#FDE5EA] text-[#CA244D] py-2 px-5 rounded-[4px]'>
      {' '}
      <ExclamationTriangleIcon /> Disconnected{' '}
    </div>
  );
};
export default ConnectionCard;
